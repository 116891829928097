const messages = {
  newtext: {
    senta: "กลับ",
    sentb: "กำไรประจำวันที่เคลื่อนไหว",
    sentc: "กำไรเท่าเทียมน้ำหนัก",
    sentd: "กรุณายืนยันการลงทุน",
    sentde: "ทั่วโลก",
    sentf: "การเกณฑ์สมาชิกโหนด",
    sentg: "เข้าร่วม",
    senth: "การจัดสรรเงินทุน",
    senti: "จำนวนเงินที่สามารถลงทุนได้",
    sentj: "กำไร",
    sentk: "สระว่ายน้ำ",
    sentaa: "กำไรรวม",
    sentq: "กำไรที่เหลือ",
    sentaw: "แฟลช",
    sentcs: "แลกเปลี่ยน",
    sentxx: "อันดับตลาดระหว่างประเทศ",
    sentwf: "บล็อกเชน",
    sentcx: "ปัญญาประดิษฐ์",
    sentes: "ของฉัน",
    sentwz: "หน้าหลัก",
    sssssss: "ประกาศ",
    sssssssaa: "ขายหมดแล้ว",
    ssszzzzzzzzz: "ขายแล้ว",
    likkkkk: "เติมเงินอย่าออก",
    likkkkk2: "กำลังแลกเปลี่ยน โปรดอย่าออกไป",
    likkkkk3: "กำลังถอนเงิน โปรดอย่าออกไป",
    likkkkk4: "กำลังลงทะเบียนบัญชี",
    likkkkk5: "กำลังเข้าสู่ระบบบัญชี",
    likkkkk6: "ระบบนิเวศการค้า",
  },
  ate: {
    placeholder: "โปรดป้อนจำนวนเงินที่จะมัดจำ",
    title: "มัดจำ",
    title2: "จำนวนรวม",
    title3: "หมื่นชิ้น",
    title4: "ขุดเหมืองด้วยพลังการคำนวณบนเชื่อมต่อ",
    confirmPurchase: "โปรดยืนยันการซื้อ",
    cancel: "ยกเลิก",
    confirm: "ยืนยัน",
    totalNetworkComputingPower: "พลังการคำนวณรวมของเครือข่าย",
    personalTotalComputingPower: "พลังคำนวณรวมของบุคคล",
    personalComputingPower: "พลังคำนวณของบุคคล",
    cumulativeComputingPower: "พลังคำนวณสะสม",
    personalComputingPowerRecord: "บันทึกพลังคำนวณของบุคคล",
    cumulativeComputingPowerRecord: "บันทึกพลังคำนวณสะสม",
    my: "ของฉัน",
    balance: "ยอดคงเหลือ",
    purchaseRecords: "บันทึกการซื้อ",
    buyNow: "ซื้อเลย",
    outputRecord: "บันทึกผลลัพธ์",
    enterAmountToExchange: "กรอกจำนวนที่ต้องการแลกเปลี่ยน",
    exchange: "แลกเปลี่ยน",
    exchangeRecords: "บันทึกการแลกเปลี่ยน",
    kLineChart: "แผนภูมิเส้น K",
    minute: "นาที",
    hour: "ชั่วโมง",
    day: "วัน",
    week: "สัปดาห์",
    purchaseSuccessful: "ซื้อสำเร็จ",
    purchaseFailed: "การซื้อล้มเหลว",
    outputQuantity: "ปริมาณ",
    downline: "ลูกข่าง",
    computingPower: "กำลังคำนวณ",
    downlineAddress: "ที่อยู่ลูกข่าง",
    generation: "รุ่น",
    purchaseComputingPower: "การซื้อกำลังคำนวณ",
    cumulativeRewardRecord: "บันทึกการได้รับรางวัลสะสม",
    dasdad: "บันทึกโบนัสสะสม",
    dasdaddasdad: "การซื้อกำลังคำนวณ",
    subRewardRecord: "บันทึกผลตอบแทนลำดับขั้น",
    month: "เดือน",
    pleaseEnter: "กรุณาใส่",
    insufficientBalance: "ยอดเงินคงเหลือไม่เพียงพอ",
    exchangeSuccess: "แลกเปลี่ยนสำเร็จ",
    exchangeFail: "แลกเปลี่ยนล้มเหลว",
    exchangeRewardPower: "แลกเปลี่ยนกำลังคำนวณรางวัล",
    subAddress: "ที่อยู่ลำดับขั้น",
    adddasxx: "กรุณายืนยันการแลกเปลี่ยน",
  },
  global: {
    fillingtext: "ฟังก์ชั่นนี้ยังไม่พร้อมใช้งาน",
  },
  axios: {
    power: "ผู้ใช้ไม่ได้รับอนุญาต",
  },
  home: {
    introduce: "บทนำ",
    node: "โหนด",
    swap: "SWAP",
    Manage: "การจัดการทางการเงิน",
    Mining: "พลังการคำนวณ",
    earnings: "สินทรัพย์",
    team: "ทีม",
    Share: "แบ่งปันรหัสเชิญ",
    Records: "บันทึก",
    inviteTitle: "กรอกรหัสเชิญ",
    inviteButton: "ตกลง",
    invitePh: "กรุณากรอกรหัสอ้างอิง",
    dia1ogTitle: "ผู้ใช้ไม่ได้รับอนุญาต（โปรดรีเฟรช）",
    prompt: "กรุณากรอกรหัสคำแนะนำที่ถูกต้อง！",
    message: "คัดลอกแล้ว",
    vite: "รหัสเชื้อเชิญ",
  },
  team: {
    invest: "จำนวนเงินลงทุน",
    investssss: "จำนวนสมาชิกในทีม",
    performance: "ผลงาน",
  },
  earnings: {
    quannnnn: "น้ำหนัก",
    ProfitPool: "กองทุนผลกำไร",
    sssss: "โปรดใส่จำนวนเงินที่คุณต้องการแลกเปลี่ยน",
    flash: "ภาพนิ่ง",
    earningsxx: "ระบบเครือข่ายผิดปกติ",
    balance: "ความสมดุลของฉัน",
    withdraw: "ถอนออก",
    filling: "เติมเงิน",
    invest: "รายได้จากการลงทุน",
    team: "ผลตอบแทนที่ถ่วงน้ำหนัก",
    Share: "แบ่งปันผลกำไร",
    earnings: "รายได้ทั้งหมด",
    day: "เผยแพร่รายวัน",
    released: "ปล่อย",
    dia1og1Title: "เติมเงิน",
    dia1og1Ph: "กรอกจำนวนเงินที่เติม",
    dia1og1Banlance: "ยอดคงเหลือในกระเป๋าเงิน",
    dia1og1B1: "ยกเลิก",
    dia1og1B2: "ยืนยัน",
    dia1og2Title1: "การถอนเงิน",
    dia1og2Title2: "จำนวนเงินที่ถอนออก",
    dia1og2Title3: "ที่อยู่กระเป๋าเงินการถอนเงิน",
    dia1og2Ph1: "ป้อนจำนวนเงินที่ถอน",
    dia1og2Ph2: "ป้อนที่อยู่กระเป๋าเงินถอนเงิน",
    tokenMsg: "tokenไม่ถูกต้องกรุณาวาดใหม่",
    WithdrawalMsgOK: "การถอนได้ถูกส่งไปแล้ว",
    outalMsgOK: "การโอนเงินถูกส่ง",
    outalMsgORR: "การโอนเงินล้มเหลว",
    InsufficientResidualIncomePool: "เก็บเงินที่เหลือไม่พอ",
    TransferSameDayWithdrawal:
      "การโอนเงินในวันเดียวกันหรือรับเงินในวันเดียวกันไม่สามารถถอนออกได้",
    AccountNotReceivedWithdrawal:
      "บัญชียังไม่ได้รับเงิน ดังนั้นยังไม่สามารถถอนออกได้",
    WithdrawalAmountGreaterThan100: "จำนวนเงินที่ถอนไม่สามารถเกิน 100 บาท",
    WithdrawalAmountGreaterThan300: "จำนวนเงินที่ถอนไม่สามารถเกิน 300 บาท",
    WithdrawalAmountGreaterThan500: "จำนวนเงินที่ถอนไม่สามารถเกิน 500 บาท",
    RechargeMsgOK: "การเติมเงินได้ถูกส่งไปแล้ว",
    WithdrawalMsgEORR: "การถอนเงินล้มเหลว",
    network: "เครือข่ายไม่ดี",
    Directthrust: "คาดว่า จะได้ผลตอบแทนตรง",
    desssL: "ผลตอบแทนจากการแนะนำโดยตรง",
    manage: "จัดการรายได้",
    enter: "กรุณาป้อน",
  },
  Mining: {
    Buyin: "วิธีการรวม:",
    onchain: "บนเชน",
    System: "ระบบคอมพิวติ้งพาวเวอร์",
    truth: "ข้อมูลจริงบนเชน สามารถถอนกลับได้จากเชน",
    permanent: "ถาวร",
    stabilize: "ทำให้มั่นคง",
    equity: "ความยุติธรรม",
    just: "เป็นธรรม",

    machine: "กำลังคอมพิวเตอร์",
    minitype1: "พลังประมวลผลระดับไมโคร",
    minitype2: "พลังการประมวลผลขนาดเล็ก",
    minitype3: "พลังการประมวลผลปานกลาง",
    minitype4: "พลังการประมวลผลขนาดใหญ่",
    Buy: "สังเคราะห์",
  },
  node: {
    ktou: "จำนวนเงินที่สามารถลงทุนได้",
    tou: "ลงทุน",
    ytou: "รายได้จากการลงทุนที่คาดว่า",
    ltou: "ลงทุนเดี๋ยวนี้",
    qtou: "โปรดใส่จำนวนเงินที่คุณต้องการลงทุน",

    by: "โดย",
    laboratory: "ห้องปฏิบัติการ",
    forged: "สร้างขึ้น",
    ecology: "ระบบนิเวศการซื้อขายอัจฉริยะ",
    Recruitment: "รับสมัครโหนดทั่วโลก",
    Dynamic: "รางวัลไดนามิก",
    may: "อาจ",
    fold: "พับ",
    obtained: "ได้รับ",

    titleBig: "โหนด",
    title1: "swapสลับโหนดเริ่มต้น",
    title2: "swapสลับซุปเปอร์โหนด",
    title3: "swapการสร้างโหนด",
    tip1: "เหรียญUSDE",
    tip2: "ชื่อ）",
    button: "ซื้อเลย",
  },
  swap: {
    text: "การแลกเปลี่ยนสำเร็จ",
    title: "SWAP",
    button0: "ไม่มีโหนด",
    button1: "โหนดเริ่มต้น",
    button2: "ซูเปอร์โหนด",
    button3: "โหนด ที่ถูกสร้าง",
    balance: "สมดุล",
    button: "ตกลง",
  },
  Manage: {
    Participation: "วิธีการเข้าร่วม",
    deposit: "วิธีการฝากเงิน",
    withdraw: "วิธีการถอนเงิน",
    invest: "จำนวนเงินลงทุนและผลตอบแทน:",
    times: "เท่า",
    Daily: "รายได้รายวัน",

    title: "การจัดการกองทุน",
    input_num: "ปริมาณที่ป้อน",
    nswb: "ยอดคงเหลือในกระเป๋าเงิน",
    amount: "เงินฝาก",
    ecumIncome: "รายได้สะสมโดยประมาณ",
    classInterest: "ความสนใจในชั้นเรียน",
    unit: "ครั้ง",
    inamount: "ลงทุน",
    next: "ถัดไป",
    minimum: "ขั้นต่ำ",
    balance: "ไม่สมดุล",
    mang: "ทองคำสำเร็จ",
  },
  Records: {
    inmoy: "โอนเข้า",
    outmoy: " โอนออก",
    zzzddd: "การรับเงิน",
    uaddd: "บันทึกการโอนเงิน",
    userAddress: "จำนวนเงินที่โอน",
    listsss: "บันทึก",
    listss: "แลกเปลี่ยน",
    unwithdraw: "เงินสด",
    type: "ประเภท",
    earnings: "รายได้จากการแลกเปลี่ยนของโหนดล่าง",
    deposit: "การฝากของผู้ใช้",
    Buy: "ซื้อโหนด",
    found: "เวลาที่สร้าง",
    Lv: "ระดับโหนด",
    daymo: "รายได้รายวัน",
    addmo: " คาดการณ์การเพิ่มขึ้นของสระว่ายน้ำรายได้",
    addmot: "คาดการณ์ผลตอบแทนที่ถ่วงน้ำหนัก",
    amount: "จำนวนเงินฝาก",
    downline: "ที่อยู่ของล่าง",
    my: "ที่อยู่ของฉัน",
    Transfer: "การถ่ายโอน",
    investment: "การลงทุน",
    depsmorng: "บันทึกการฝากเงิน",
    Recharge: "บันทึกการเติมเงิน",
    Withdrawal: "บันทึกการถอนเงิน",
    qpang: "อื่น ๆ",
    topup: "เติมเงิน",
    address: "ที่อยู่",
    withdraw: "ถอนออก",
    classInterest: "ความสนใจในชั้นเรียน",
    complete: "เสร็จสิ้น",
    underway: "กำลังชาร์จ",
    Lose: "เติมเงินล้มเหลว",
    unwithLose: "การถอนเงินล้มเหลว",
    ate1: "รางวัลฝากเงิน ATE แฮชเรท",
    ate2: " รางวัลดอกเบี้ยทบต้น ATE แฮชเรท",
  },
  introduce: {
    t1: "ประดิษฐ์",
    t2: "การซื้อขายอัจฉริยะ",
    content: "มุ่งมั่นที่จะสร้างระบบนิเวศทางเศรษฐกิจการสร้างเนื้อหาใหม่ในอนาคต",
    title: [
      "การซื้อขายอัจฉริยะด้วย AI",
      "โอกาสยิ่งใหญ่",
      "การเพิ่มขึ้นของ",
      "การซื้อขายที่ไม่จำเป็นต้องมีกลาง",
      "โมดูลการซื้อขายสินค้าอนุพันธ์",
      "บล็อกเชน",
      "AI",
      "ทีมผู้เชี่ยวชาญด้านเทคโนโลยี",
      "เกี่ยวกับเรา",
      "รูเบน แฟลค",
      "วิทยาศาสตร์ทางเศรษฐศาสตร์",
      "แบบจำลอง",
    ],
    value: [
      "ตลาดสกุลเงินดิจิทัลลงมาในช่วงต้นปีจาก 2.2 ล้านล้านดอลลาร์ลงมาเหลือ 850 ล้านดอลลาร์ ส่งผลกระทบมากในการลดความเชื่อมั่นของนักลงทุนและความต้องการในการพิเศษ รวมถึงค่ายองค์กรที่ลงทุนใน DeFi ทั้งหมด ที่ลดลงจาก 167 ล้านดอลลาร์ในช่วงต้นปีลงมาเหลือ 44 ล้านดอลลาร์ อย่างไรก็ตาม ตลาดที่ไม่มีเทียบเท่าขณะที่ตลาดอยู่ในสถานการณ์ขาลงยาวนานและความผันผวนที่ต่ำ ทำให้การซื้อขายเป็นเรื่องยากและท้าทายมาก",
      "การซื้อขายปริมาณมากเสมอเป็นส่วนที่มีกำไรมากที่สุดในตลาดการเงิน เช่นเดียวกับในบรรดากิจกรรมการซื้อขายของสกุลเงินดิจิทัล ที่มีการเกิดเหตุการณ์ต่าง ๆ เช่นการควบคุมบล็อกโดยบริษัทการซื้อขายที่ไม่มีส่วนร่วมในตลาด และเหตุการณ์อื่น ๆ อีกมากมาย ทำให้ความไม่เชื่อถือของผู้ใช้ในการซื้อขายเพิ่มขึ้น และประเด็นหลายปัจจัยเข้ามามีผลรวมทำให้ความแข็งแกร่งในการแข่งขันในตลาดการซื้อขายปริมาณมากเป็นโอกาสให้เกิดขึ้น",
      "Ai Trading Ecology ได้รับการสนับสนุนด้วยเงินลงทุนจากหน่วยงานลงทุนชั้นนำของสหรัฐ NEO Global cap, BlombergBeta,ร่วมก่อตั้งและลงทุนในห้องปฏิบัติการ Brain Lab",
      "แพลตฟอร์ม Bloomberg,Brain Lab มีการเน้นในระบบการซื้อขายอัจฉริยะด้วยบล็อกเชนและปัจจัยประหยัดทางการเงิน โดยมุ่งเน้นให้ระบบการซื้อขายรวมถึงระบบผู้สร้างตลาดแบบอัตโนมัติ การลงทุนร่วมกันในตลาดและระบบการบริหารการเงินในอนาคต และระบบในอนาคตนี้จะสร้างการซื้อขายที่มีลักษณะคล้ายกับการซื้อขายผลิตภัณฑ์อนุพันธ์ใน DeFi รวมถึงการสร้างนิเวศและระบบนิเวศในอนาคต",
      "Brain Lab เป็นห้องปฏิบัติการวิจัยที่เน้นในด้านปัญญาประดิษฐ์และการซื้อขายสินทรัพย์ดิจิทัลบล็อกเชน ห้องปฏิบัติการนี้ก่อตั้งขึ้นในปี 2020 มีเป้าหมายที่จะค้นคว้าวิธีการของอัลกอริทึมและฟังก์ชันของปัญญาประดิษฐ์ AI ที่จะพัฒนาเทคโนโลยีการซื้อขายดิจิทัลและการสร้างผู้สร้างตลาดอัตโนมัติในนามของ AI และมุ่งมั่นที่จะให้ประสบการณ์การซื้อขายที่เสถียร รวดเร็ว สะดวก และโปร่งใสให้กับนักซื้อขาย",
    ],
    users: ["Reuben Flack", "Sanjay Vallabh", "Renuka Kuech", "Alex Baboolal"],
    modelData: [
      "การหล่อ",
      "การจัดการคำสั่งโดยใช้ AI",
      "เข้าถึงทองคำเพื่อรับได้",
      "การออกไปเมื่อมีกำไร 2-3 เท่า",
      "การผลิต",
      "การสังเคราะห์",
      "เครื่องขุดแรงงาน",
      "ATE",
      "มูลค่าขุดทอง",
    ],
    userMsg: [
      "ผู้ใช้สามารถรับประสิทธิภาพความสามารถการคำสั่ง AI ของ USDT+USDE ที่มีประสิทธิภาพ 2.3-3 เท่า",
      "ผู้ใช้สังเคราะห์และผลิตเครื่องขุดเพื่อผลิต ATE ผ่านการรวมกันของ USDT+USDE อัตราส่วน 1:1",
    ],
    foots: [
      "ATE",
      "CDM",
      "ปริมาณการเผยแพร่รวมของ ATE",
      "หมื่น",
      "ผลต่างของอินเฟเชียลมากเป็น",
      "10,000 ชิ้น",
      "5หมื่นหนึ่งส่วนในปริมาณทั้งหมดที่เหลือคง ถูกขุดเข้ามาทุกวัน (ลดปริมาณการผลิต)",
      "0 การระดมทุน, 0 การผลิตสำรอง, 0 การสมัครสมาชิก",
      "ATE ที่ขายทั้งหมดถูกทำลาย 100%",
      "TRADING ECOLOGY",
      "มุมมองข้างหน้า",
      "ในโลกของ Ai Trading Ecology, ATE คือโทเค็นหลักของระบบ 3.0 ของนิเวศของ Ai Trading Ecologyและจะถูกใช้เป็นหลักทรัพย์มัดจำในการขุดตัวโทเค็นหลักในอนาคต ด้วยเทคโนโลยีการเชื่อมโยงไปที่ทุกส่วน",
      "การเดินทางข้ามเครือ, มีทางไปที่แตกต่าง, การเงินที่ไม่จำเป็นต้องมี",
      "การให้ยืมและอื่น ๆ ในอุตสาหกรรมการแจกจ่าย จะใช้เทคโนโลยีการเชื่อมต่อได้ทั่วโลก เพื่อใช้เป็นหลักทรัพย์ในการขุดตัวโทเค็นหลัก โดยมีพื้นที่สูงสุดของมูลค่าในอนาคต!",
    ],
  },
};

export default messages;
