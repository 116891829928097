const messages = {
  newtext: {
    senta: "돌아가기",
    sentb: "일일 동적 수익",
    sentc: "가중치 동등 수준 수익",
    sentd: "투자 확인 요망",
    sentde: "글로벌",
    sentf: "노드 모집",
    sentg: "참여",
    senth: "자금 배분",
    senti: "투자 가능 금액",
    sentj: "수익",
    sentk: "풀",
    sentaa: "총 수익",
    sentq: "남은 수익",
    sentaw: "플래시",
    sentcs: "교환",
    sentxx: "국제 시장 순위",
    sentwf: "블록체인",
    sentcx: "인공 지능",
    sentes: "나의",
    sentwz: "홈페이지",
    sssssss: "공고",
    sssssssaa: "매진되다",
    ssszzzzzzzzz: "판매 중",
    likkkkk: "충전 중이니 자리를 뜨지 마세요",
    likkkkk2: "교환 중, 떠나지 마세요",
    likkkkk3: "출금 중, 떠나지 마세요",
    likkkkk4: "계정 등록 중",
    likkkkk5: "계정 로그인 중",
    likkkkk6: "거래 생태계",
  },
  ate: {
    placeholder: "담보금을 입력하세요",
    title: "담보금",
    title2: "총 발행량",
    title3: "만 개",
    title4: "체인 상 담보금 컴퓨팅 파워 마이닝",
    confirmPurchase: "구매를 확인하십시오",
    cancel: "취소",
    confirm: "확인",
    totalNetworkComputingPower: "전체 네트워크 컴퓨팅 파워",
    personalTotalComputingPower: "개인 총 컴퓨팅 파워",
    personalComputingPower: "개인 컴퓨팅 파워",
    cumulativeComputingPower: "누적 컴퓨팅 파워",
    personalComputingPowerRecord: "개인 컴퓨팅 파워 기록",
    cumulativeComputingPowerRecord: "누적 컴퓨팅 파워 기록",
    my: "내",
    balance: "잔고",
    purchaseRecords: "구매 기록",
    buyNow: "지금 구매",
    outputRecord: "생산 기록",
    enterAmountToExchange: "교환할 금액 입력",
    exchange: "교환",
    exchangeRecords: "교환 기록",
    kLineChart: "K 라인 차트",
    minute: "분",
    hour: "시간",
    day: "일",
    week: "주",
    purchaseSuccessful: "구매 성공",
    purchaseFailed: "구매 실패",
    computingPower: "컴퓨팅 파워",
    outputQuantity: "수량",
    downline: "하위",
    downlineAddress: "하위 주소",
    generation: "세대",
    purchaseComputingPower: "컴퓨팅 파워 구매",
    cumulativeRewardRecord: "누적 보상 기록",
    dasdad: "더블 보너스 기록",
    dasdaddasdad: "컴퓨팅 파워 구매",
    subRewardRecord: "하위 보상 기록",
    month: "월",
    pleaseEnter: "입력하세요",
    insufficientBalance: "잔액 부족",
    exchangeSuccess: "교환 성공",
    exchangeFail: "교환 실패",
    exchangeRewardPower: "보상 계산력 교환",
    subAddress: "하위 주소",
    adddasxx: "교환을 확인해 주세요",
  },
  global: {
    fillingtext: "이 기능은 아직 열리지 않았습니다",
  },
  axios: {
    power: "사용자에게 권한이 없습니다",
  },
  home: {
    dia1ogTitle: "사용자에게 권한이 없습니다. 새로 고치십시오.",
    introduce: "소개",
    node: "노드",
    swap: "SWAP",
    Manage: "재무 관리",
    Mining: "채굴력",
    earnings: "자산",
    team: "팀",
    Share: "초대장 공유",
    Records: "기록",
    inviteTitle: "초대 코드 입력",
    inviteButton: "확인",
    invitePh: "추천 코드를 입력하세요",
    dialogTitle: "사용자가 권한이 없습니다 (새로 고침해주세요)",
    prompt: "유효한 추천 코드를 입력하세요!",
    message: "복사됨",
    vite: "초대 코드",
  },
  team: {
    invest: "투자 금액",
    investssss: "팀 인원",
    performance: "성과",
  },
  earnings: {
    quannnnn: "가중치 ",
    ProfitPool: "수익 풀",
    sssss: "교환하려는 금액을 입력하세요",
    flash: "삐-",
    earningsxx: "네트워크 이상",
    dia1og1Title: "충전",
    dia1og1Ph: "충전 금액 입력",
    dia1og1Banlance: "지갑 잔액",
    dia1og1B1: "취소",
    dia1og1B2: "확인",
    dia1og2Title1: "출금",
    dia1og2Title2: "출금 금액",
    dia1og2Title3: "출금 지갑 주소",
    dia1og2Ph1: "출금 금액 입력",
    dia1og2Ph2: "출금 지갑 주소 입력",
    balance: "내 잔액",
    withdraw: "출금",
    filling: "충전",
    invest: "투자 수익",
    team: "가중 수익",
    Share: "공유 수익",
    earnings: "총 수익",
    day: "일일 릴리스",
    released: "이미 릴리스됨",
    dialog1Title: "충전",
    dialog1Ph: "충전 금액 입력",
    dialog1Balance: "지갑 잔액",
    dialog1B1: "취소",
    dialog1B2: "확인",
    dialog2Title1: "출금",
    dialog2Title2: "출금 금액",
    dialog2Title3: "출금 지갑 주소",
    dialog2Ph1: "출금 금액 입력",
    dialog2Ph2: "출금 지갑 주소 입력",
    tokenMsg: "토큰이 유효하지 않습니다, 다시 그려주세요",
    WithdrawalMsgOK: "출금이 제출되었습니다",
    outalMsgOK: "송금이 제출되었습니다",
    outalMsgORR: "송금 실패",
    InsufficientResidualIncomePool: "잔여 수익 풀 부족",
    TransferSameDayWithdrawal: "당일 이체 또는 당일 수령된 경우 철회할 수 없음",
    AccountNotReceivedWithdrawal: "계정이 아직 받지 않았으므로 철회할 수 없음",
    WithdrawalAmountGreaterThan100: "인출 금액은 100원을 초과할 수 없음",
    WithdrawalAmountGreaterThan300: "인출 금액은 300원을 초과할 수 없음",
    WithdrawalAmountGreaterThan500: "인출 금액은 500원을 초과할 수 없음",
    RechargeMsgOK: "충전이 제출되었습니다",
    WithdrawalMsgEORR: "출금 실패",
    RechargeMsgERROR: "충전 실패",
    network: "네트워크 상태가 좋지 않습니다",
    Directthrust: "예상 직추 수익",
    desssL: "직접 추천 수익",
    manage: "관리 수익",
    enter: "입력해주세요",
  },
  Mining: {
    Buyin: "합성 방법:",
    onchain: "체인 위에",
    System: "연산 능력 시스템",
    truth: "실제 체인 데이터, 체인에서 검색 가능",
    permanent: "영구적인",
    stabilize: "안정화",
    equity: "공정",
    just: "정의",

    machine: "채굴력",
    minitype1: "미니 채굴력",
    minitype2: "소형 채굴력",
    minitype3: "중형 채굴력",
    minitype4: "대형 채굴력",
    Buy: "합성",
  },
  node: {
    ktou: "투자 가능 금액",
    tou: "투자",
    ytou: "예상 투자 수익",
    ltou: "지금 투자",
    qtou: "투자하고자 하는 금액을 입력하세요",

    by: "에 의해",
    laboratory: "실험실",
    forged: "만들어진",
    ecology: "스마트 트레이딩 생태계",
    Recruitment: "글로벌 노드 모집",
    Dynamic: "다이나믹 보상",
    may: "할 수 있다",
    fold: "접다",
    obtained: "획득한",

    titleBig: "노드",
    title1: "SWAP 초기 노드",
    title2: "SWAP 슈퍼 노드",
    title3: "SWAP 창세 노드",
    tip1: "할인된 USDE",
    tip2: "자리)",
    button: "지금 구매",
  },
  swap: {
    text: "교환 성공",
    title: "SWAP",
    button0: "현재 노드 없음",
    button1: "초기 노드",
    button2: "슈퍼 노드",
    button3: "창세 노드",
    balance: "잔액",
    button: "확인",
  },
  Manage: {
    Participation: "참여 방법",
    deposit: "입금 방법",
    withdraw: "출금 방법",
    invest: "투자 금액 및 수익:",
    times: "배",
    Daily: "일일 수익",

    title: "자금 관리",
    input_num: "금액 입력",
    nswb: "지갑 잔액",
    amount: "입금",
    ecumIncome: "예상 누적 수익",
    classInterest: "등급 이익",
    unit: "배",
    inamount: "투자",
    next: "다음 단계",
    minimum: "최소",
    balance: "잔액이 부족하다",
    mang: "금벌이에 성공하다",
  },
  Records: {
    inmoy: "입금 ",
    outmoy: "출금",
    zzzddd: "수금",
    uaddd: "이체 기록",
    userAddress: " 이체 금액",
    listsss: "기록",
    listss: "교환",
    unwithdraw: "현금 인출 중",
    type: "유형",
    earnings: "하위 노드 교환 수익",
    deposit: "사용자 입금",
    Buy: "노드 구매",
    found: "생성 시간",
    Lv: "노드 레벨",
    daymo: "일일 수익",
    addmo: "예상 수익 풀 증가",
    addmot: "예상 가중 수익",
    amount: "입금 금액",
    downline: "하위 주소",
    my: "내 주소",
    Transfer: "이체",
    investment: "투자",
    depsmorng: "입금 기록",
    Recharge: "충전 기록",
    Withdrawal: "출금 기록",
    qpang: "기타",
    topup: "충전",
    address: "주소",
    withdraw: "출금",
    classInterest: "등급 이익",
    complete: "완료됨",
    underway: "충전 중",
    Lose: "충전 실패",
    unwithLose: "출금 실패",
    ate1: "입금 보상 ATE 해시 레이트",
    ate2: "복리 보상 ATE 해시 레이트",
  },
  introduce: {
    t1: "인공",
    t2: "지능 거래",
    content: "미래 콘텐츠 창작 경제의 새로운 생태계 구축에 힘쓰고 있습니다",
    title: [
      "AI 지능형 거래",
      "거대한 기회",
      "급증하는",
      "탈중앙화",
      "파생 상품 거래 모듈",
      "블록체인",
      "AI",
      "기술 팀",
      "소개",
      "루벤 플랙",
      "경제학",
      "모델",
    ],
    value: [
      "올해 초부터 암호화폐 시가총액이 2.2조 달러에서 8,500억 달러로 하락했으며 긴 약세장이 투자자 신뢰와 투기 수요를 크게 충격을 주었고 DeFi 프로토콜의 총 잠금 가치(TVL)는 연초 1,670억 달러에서 440억 달러로 하락했습니다. 그러나 약세장에서의 장기 변동성과 낮은 변동성은 거래를 매우 어렵고 도전적으로 만듭니다.",
      "양적 거래는 항상 금융 분야에서 가장 수익성이 높은 시장 세그먼트였습니다. 최근 몇 년 동안 암호화 거래 분야에서 중앙 집중식 거래소의 블랙박스 조작, FTX 폭풍과 같은 다양한 사건이 빈번하게 발생해 거래소에 대한 사용자들의 불신이 증가하고 있으며, 여러 요인이 중첩되어 양적 거래 경주로에 기회를 가져다주고 있습니다.",
      "Ai Trading Ecology는 미국 투자 기관 NEO Global cap, BlombergBeta, 루마니아 투자 기관 BlockFront, Capital, Brain Lab 실험실에 공동으로 창립하고 투자하다.",
      "Brain Lab 플랫폼, Brain Lab은 블록체인 AI 지능형 양적 거래 시스템, 자동 시장 메이커, 공동 차익 투자 및 재무 관리 시스템에 중점을 두고 있으며, 미래 시스템은 defi 파생 상품 분산형 거래 및 공공 체인 및 생태계를 만들 것입니다.",
      "Brain Lab은 AI 지능과 블록체인 암호화 자산 거래에 중점을 둔 연구 실험실입니다. 2020년에 설립된 이 실험실은 AI 인공 지능의 알고리즘 메커니즘과 기능을 탐구하고 새로운 AI 지능형 자동 거래 및 기술을 개발하여 AI 인공 지능 자동화 시장 메이커를 실현하고 거래자에게 안정적이고 빠르며 편리하고 투명한 거래 경험을 제공하는 것에 전념하고 있습니다.",
    ],
    users: ["Reuben Flack", "Sanjay Vallabh", "Renuka Kuech", "Alex Baboolal"],
    modelData: [
      "주조",
      "AI 관리 주문",
      "금에 접근하여 획득",
      "2-3배 수익이면 탈출",
      "출력",
      "합성",
      "파워 채굴 기계",
      "ATE",
      "파워 값",
    ],
    userMsg: [
      "사용자는 USDT+USDE 주조 AI 주문을 통해 2.3-3배에 해당하는 컴퓨팅 파워 출력을 얻을 수 있습니다",
      "사용자들은 1:1 USDT+USDE 조합을 통해 ATE를 생산하기 위해 합성 채굴기를 만듭니다",
    ],
    foots: [
      "ATE",
      "CDM",
      "ATE 총 발행량",
      "만",
      "극도의 디플레이션으로",
      "10,000 개",
      "남은 총량의 5만분의 1이 매일 채굴됩니다(감소하는 출력)",
      "0 사모, 0 예비 채굴, 0 구독",
      "판매된 ATE 100% 파괴",
      "TRADING ECOLOGY",
      "미래 전망",
      "Ai Trading Ecology생태계 주요 증명서, 3.0 생태계 파생 상품에서 ATE는 Ai Trading Ecology생태계 체인의 주요 토큰이며, 사용자",
      "체인 여행, 메타버스, 탈중앙화",
      "대출 및 기타 유통 분야에서도 크로스체인 기술을 통해 담보로 사용되어 주요 토큰 채굴을 실현하며, 미래 가치 상승의 엄청난 공간이 있을 것입니다!",
    ],
  },
};

export default messages;
