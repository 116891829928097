const messages = {
  newtext: {
    senta: "Return",
    sentb: "Daily Dynamic Profit",
    sentc: "Weighted Equal Level Profit",
    sentd: "Please Confirm Investment",
    sentde: "Global",
    sentf: "Node Recruitment",
    sentg: "Participate",
    senth: "Capital Allocation",
    senti: "Investable Amount",
    sentj: "Profit",
    sentk: "Pool",
    sentaa: "Total Profit",
    sentq: "Remaining Profit",
    sentaw: "Flash",
    sentcs: "Exchange",
    sentxx: "International Market Rankings",
    sentwf: "Blockchain",
    sentcx: "Artificial Intelligence",
    sentes: "My",
    sentwz: "Home Page",
    sssssss: "announcement",
    sssssssaa: "Sold out",
    ssszzzzzzzzz: "For Sale",
    likkkkk: "Recharge, please do not leave",
    likkkkk2: "Exchanging, please do not leave.",
    likkkkk3: "Withdrawing, please do not leave",
    likkkkk4: "Registering account",
    likkkkk5: "Logging in to account",
    likkkkk6: "Trading Ecosystem",
  },
  ate: {
    placeholder: "Please enter the amount to pledge",
    title: "Pledge",
    title2: "Total Issuance",
    title3: "Ten Thousand Pieces",
    title4: "On-chain Pledge Computing Power Mining",
    confirmPurchase: "Please confirm purchase",
    cancel: "Cancel",
    confirm: "Confirm",
    totalNetworkComputingPower: "Total network computing power",
    personalTotalComputingPower: "Personal total computing power",
    personalComputingPower: "Personal computing power",
    cumulativeComputingPower: "Cumulative computing power",
    personalComputingPowerRecord: "PCP Record",
    cumulativeComputingPowerRecord: "CCP Record",
    my: "My",
    balance: "Balance",
    purchaseRecords: "Purchase records",
    buyNow: "Buy now",
    outputRecord: "Output record",
    enterAmountToExchange: "Enter amount to exchange",
    exchange: "Exchange",
    exchangeRecords: "Exchange records",
    kLineChart: "K-line chart",
    minute: "Minute",
    hour: "Hour",
    day: "Day",
    week: "Week",
    purchaseSuccessful: "Purchase successful",
    purchaseFailed: "Purchase failed",
    computingPower: "Computing Power",
    outputQuantity: "Output Quantity",
    downline: "Downline",
    downlineAddress: "Downline Address",
    generation: "Generation",
    purchaseComputingPower: "Purchase Computing Power",
    cumulativeRewardRecord: "Cumulative Reward Record",
    dasdad: "Cumulative Reward Record",
    dasdaddasdad: "Purchase Computing Power",
    subRewardRecord: "Subordinate Reward Record",
    month: "Month",
    pleaseEnter: "Please Enter",
    insufficientBalance: "Insufficient Balance",
    exchangeSuccess: "Exchange Successful",
    exchangeFail: "Exchange Failed",
    exchangeRewardPower: "Exchange Reward Computing Power",
    subAddress: "Subordinate Address",
    adddasxx: "Please confirm the exchange",
  },
  global: {
    fillingtext: "This function is not available yet",
  },
  axios: {
    power: "User not authorized",
  },
  home: {
    dia1ogTitle: "User not authorized (please refresh)",
    introduce: "Introduction",
    node: "Node",
    swap: "SWAP",
    Manage: "Finance",
    Mining: "Hashrate",
    earnings: "assets",
    team: "Team",
    Share: "Share invitation code",
    Records: "Records",
    inviteTitle: "Enter Invitation Code",
    inviteButton: "Confirm",
    invitePh: "Please enter the referral code",
    dialogTitle: "User not authorized (please refresh)",
    prompt: "Please enter a valid referral code!",
    message: "Copied",
    vite: "Invitation code",
  },
  team: {
    invest: "Investment Amount",
    investssss: "Team Size",
    performance: "Performance",
  },
  earnings: {
    quannnnn: "Weight",
    ProfitPool: "Profit Pool",
    sssss: "Please enter the amount you want to exchange",
    flash: "flash",
    earningsxx: "Network exception",
    balance: "My Balance",
    withdraw: "Withdraw",
    filling: "Deposit",
    invest: "Investment Earnings",
    team: "Weighted Return",
    Share: "Share Earnings",
    earnings: "Total Earnings",
    day: "Daily Release",
    released: "Already Released",
    dia1og1Title: "Deposit",
    dia1og1Ph: "Enter deposit amount",
    dia1og1Banlance: "Wallet Balance",
    dia1og1B1: "Cancel",
    dia1og1B2: "Confirm",
    dia1og2Title1: "Withdrawal",
    dia1og2Title2: "Withdrawal Amount",
    dia1og2Title3: "Withdrawal Wallet Address",
    dia1og2Ph1: "Enter withdrawal amount",
    dia1og2Ph2: "Enter withdrawal wallet address",
    tokenMsg: "Token invalid, please redraw",
    WithdrawalMsgOK: "Withdrawal has been submitted",
    outalMsgOK: "Transfer Submitted",
    outalMsgORR: "Transfer Failed",
    InsufficientResidualIncomePool: "Insufficient residual income pool",
    TransferSameDayWithdrawal:
      "Transfer has been transferred or received on the same day and cannot be withdrawn",
    AccountNotReceivedWithdrawal:
      "The account has not been received yet, so you cannot withdraw it yet",
    WithdrawalAmountGreaterThan100:
      "The withdrawal amount cannot be greater than 100",
    WithdrawalAmountGreaterThan300:
      "The withdrawal amount cannot be greater than 300",
    WithdrawalAmountGreaterThan500:
      "The withdrawal amount cannot be greater than 500",
    RechargeMsgOK: "Recharge has been submitted",
    WithdrawalMsgEORR: "Withdrawal failed",
    RechargeMsgERROR: "Deposit failed",
    network: "Poor network",
    Directthrust: "Expected direct earnings",
    desssL: "Direct Push Benefit",
    manage: "Management Earnings",
    enter: "Please enter",
  },
  Mining: {
    Buyin: "Synthesis method:",
    onchain: "On-chain",
    System: "Computing power system",
    truth: "Real on-chain data, retrievable on-chain",
    permanent: "Permanent",
    stabilize: "Stabilize",
    equity: "Equity",
    just: "Just",

    machine: "Hashrate",
    minitype1: "Micro Hashrate",
    minitype2: "Small Hashrate",
    minitype3: "Medium Hashrate",
    minitype4: "Large Hashrate",
    Buy: "Synthesize",
  },
  node: {
    ktou: "Investable Amount",
    tou: "Invest",
    ytou: "Estimated Investment Earnings",
    ltou: "Invest Now",
    qtou: "Please enter the amount you want to invest",

    by: "by",
    laboratory: "laboratory",
    forged: "forged",
    ecology: "a smart trading ecosystem",
    Recruitment: "Global node recruitment",
    Dynamic: "Dynamic rewards",
    may: "may",
    fold: "fold",
    obtained: "obtained",

    titleBig: "Node",
    title1: "SWAP Initial Node",
    title2: "SWAP Super Node",
    title3: "SWAP Genesis Node",
    tip1: "Discounted USDE",
    tip2: "spots)",
    button: "Buy Now",
  },
  swap: {
    text: "Successful exchange",
    title: "SWAP",
    button0: "No Node",
    button1: "Initial Node",
    button2: "supernode",
    button3: "Genesis node",
    balance: "Balance",
    button: "Confirm",
  },
  Manage: {
    Participation: "Participation method",
    deposit: "Deposit method",
    withdraw: "Withdrawal method",
    invest: "Investment amount and returns:",
    times: "times",
    Daily: "Daily returns",

    title: "Fund Management",
    input_num: "Enter Amount",
    nswb: "Wallet Balance",
    amount: "Deposit",
    ecumIncome: "Expected Cumulative Earnings",
    classInterest: "Level Benefits",
    unit: "times",
    inamount: "invest",
    next: "Next Step",
    minimum: "minimum",
    balance: "Insufficient balance",
    mang: "Success in gold",
  },
  Records: {
    inmoy: "Transfer In",
    outmoy: "Transfer Out",
    zzzddd: "Collection",
    uaddd: "Transfer Record",
    userAddress: " Transfer Amount",
    listsss: "Record",
    listss: "Exchange",
    unwithdraw: "Withdrawing",
    type: "Type",
    earnings: "Subordinate node exchange earnings",
    deposit: "User deposit",
    Buy: "Purchase node",
    found: "Creation time",
    Lv: "Node level",
    daymo: "Daily Profit",
    addmo: "Estimated Increase in Revenue Pool",
    addmot: "Estimated Weighted Return",
    amount: "Deposit amount",
    downline: "Subordinate address",
    my: "My address",
    Transfer: "Transfer",
    investment: "investment",
    depsmorng: "Deposit Record",
    Recharge: "Deposit Records",
    Withdrawal: "Withdrawal Records",
    qpang: "other",
    topup: "Deposit",
    address: "Address",
    withdraw: "Withdraw",
    classInterest: "Level Benefits",
    complete: "Completed",
    underway: "Deposit In Progress",
    Lose: "Deposit Failed",
    unwithLose: "Withdrawal Failed",
    ate1: "Deposit Reward ATE Hashrate",
    ate2: "Compound Interest Reward ATE Hashrate",
  },
  introduce: {
    t1: "Artificial",
    t2: "Intelligent Trading",
    content:
      "Committed to creating a new ecosystem for future content creation economy",
    title: [
      "AI intelligent trading",
      "Great opportunity",
      "Runaway",
      "Decentralization",
      "Derivative trading module",
      "Blockchain",
      "AI",
      "Technical team",
      "Introduce",
      "Reuben Flack",
      "Economics",
      "Model",
    ],
    value: [
      "Since the beginning of the year, the market value of cryptocurrency has fallen from $2.2 trillion to $850 billion, and the long bear market has greatly hit investor confidence and speculative demand, and the total lock-up value of DeFi protocol (TVL) has fallen from $167 billion at the beginning of the year to $44 billion. However, long periods of volatility and low volatility in bear markets make trading extremely difficult and challenging.",
      "Quantitative trading has always been the most profitable market segment in the financial field. In recent years, various events have occurred frequently in the field of crypto trading, such as black box manipulation of centralized exchanges, FTX thunderstorms and other events, resulting in increasing distrust of users on exchanges and multiple factors superimposed, which are bringing opportunities for quantitative trading racetrack",
      "Ai Trading Ecology is funded by U.S. investment institutions NEO Global cap and BlombergBeta and Romanian investment institutions BlockFront, Jointly initiated and invested in Brain Lab laboratory.",
      "Bloomberg Platform, Brain Lab focuses on blockchain AI intelligent quantitative trading system, automatic market maker, joint arbitrage investment and financial management system, the future system will create defi derivatives decentralized trading and public chain and ecology.",
      "Brain Lab is a research lab focused on AI intelligence and blockchain crypto asset trading. Established in 2020, the lab is committed to exploring the algorithm mechanism and function of AI artificial intelligence, and is committed to developing new AI intelligent automatic trading and technology, so as to realize AI artificial intelligence automated market makers and provide stable, fast, convenient and transparent trading experience for traders.",
    ],
    users: ["Reuben Flack", "Sanjay Vallabh", "Renuka Kuech", "Alex Baboolal"],
    modelData: [
      "Cast",
      "AI managed order",
      "According to access to gold",
      "2-3 times earnings and you re out",
      "Output",
      "Synthesis",
      "The power mining machine",
      "ATE",
      "Power value",
    ],
    userMsg: [
      "Users can obtain 2.3-3 times equivalent computing power output through USDT+USDE foundry AI orders",
      "Users synthesize and mint mining machines to produce ATE through a 1:1 USDT+USDE combination.",
    ],
    foots: [
      "ATE",
      "CDM",
      "ATE total issued",
      "myriad",
      "Extreme deflation to",
      "10,000 pieces",
      "5 millionths of the remaining total is mined daily (declining output)",
      "0 private placement, 0 pre-mining, 0 subscription",
      "Sold ATE100% destroyed",
      "TRADING ECOLOGY",
      "future expectations",
      "Ai Trading Ecology main certificate, in 3.0 ecological derivatives ATE is the mainstream token of the Ai Trading Ecology chain, ",
      "Blockchain games, meta-universe, decentralization",
      "Lending and other areas of circulation, will also be used to pledge through the cross-chain technology to achieve mining mainstream tokens, the future appreciation of huge space!",
    ],
  },
};

export default messages;
