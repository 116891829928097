import axios from "axios";
let arr = ['/users/checkUser', '/users/getCaptcha', '/users/login','/users/checkReferralCode','/users/initUser']
import router from "@/router/index.js";
// import i18n from '@/lang/index'

axios.defaults.baseURL = 'https://api2.aitradinge.com/'; //测试https://api2.aitradinge.com/
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    config => config,
    error => Promise.reject(error)
);

axios.interceptors.request.use(
    config => {
        // 在请求发送之前做些什么
        const storedToken = localStorage.getItem('token');
        for (let i = 0; i < arr.length; i++) {
            if (config.url == arr[i]) {
                return config;
            }
        }
        if (!storedToken) {
            router.push({ name: 'introduce' })
            return config;
        }
        return config;
        // return Promise.reject(new Error('changePassword'))

    }, error => {
        // 对请求错误做些什么
        // return Promise.reject(error);
        console.log(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        }
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    },
    error => {
        // 在此处添加更详细的错误处理逻辑，如：
        // if (error.response.status === 401) {
        //     // 处理特定错误状态
        // }
        return Promise.reject(error);
    }
);

export default {
    post(url, data) {
        return axios.post(url, data);
    },

    get(url, params) {
        return axios.get(url, { params });
    }
};
