import { createRouter, createWebHashHistory } from "vue-router";
import i18n from '@/lang'

const routes = [
    {
        path: "/HomePage",
        alias: "/myHome",
        name: "HomePage",
        component: () => import("../views/NewPage/HomePage.vue"),
        children: [
            {
                path: "/HomePage/ATERecordsPage",
                component: () => import("@/views/NewPage/ATERecordsPage.vue"),
                name: 'ATERecords'
            },
            {
                path: "/HomePage/ExchangeRecord",
                component: () => import("@/views/NewPage/ExchangeRecord.vue"),
                name: 'ExchangeRecord'
            },
            {
                path: "/HomePage/AnnouncementPage",
                component: () => import("@/views/NewPage/AnnouncementPage.vue"),
                name: 'AnnouncementPage'
            },
            {
                path: "/HomePage/AnnouncementIn",
                component: () => import("@/views/NewPage/AnnouncementIn.vue"),
                name: 'AnnouncementIn'
            },
            {
                path: "/HomePage/introduce",
                component: () => import("@/views/NewPage/IntroducePage.vue"),
                name: 'introduce'
            },
            {
                path: "/HomePage/node",
                component: () => import("@/views/NewPage/NodePage.vue"),
                name: 'node'
            },
            // {
            //     path: "/HomePage/swap",
            //     component: () => import("@/views/NewPage/SwapPage.vue"),
            //     name: 'swap'
            // }, 
            // {
            //     path: "/HomePage/manage",
            //     component: () => import("@/views/NewPage/ManagePage.vue"),
            //     name: 'manage'
            // },
            {
                path: "/HomePage/mining",
                component: () => import("@/views/NewPage/MiningPage.vue"),
                name: 'mining'
            },
            {
                path: "/HomePage/earnings",
                component: () => import("@/views/NewPage/EarningsPage.vue"),
                name: 'earnings'
            },
            {
                path: "/HomePage/ATE",
                component: () => import("@/views/NewPage/ATEPage.vue"),
                name: 'ATE'
            },
            {
                path: "/HomePage/MyPage",
                component: () => import("@/views/NewPage/MyPage.vue"),
                name: 'MyPage',
                children: [
                    {
                        path: "/HomePage/MyPage/team",
                        component: () => import("@/views/NewPage/TeamPage.vue"),
                        name: 'team'
                    },
                    {
                        path: "/HomePage/Records",
                        component: () => import("@/views/NewPage/RecordsPage.vue"),
                        name: 'Records'
                    },
                    {
                        path: "/HomePage/MyPage",
                        redirect: "/HomePage/MyPage/team"
                    },
                ],

            },
            {
                path: "/HomePage",
                redirect: "/HomePage/introduce"
            },
        ],
    },
    {
        path: "/",
        redirect: "/HomePage"
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // let tok = localStorage.getItem("token")
        // if (!tok) {
        //     alert(i18n.global.t('axios.power'))
        //     return
        // }
        // 滚动行为逻辑
        return savedPosition || { top: 0 }
    }
});


export default router
