import { createApp } from 'vue';
import axios from './axios/index';
import i18n from "@/lang/index";
import 'vant/lib/index.css';
import App from './App.vue';
import router from "@/router/index.js";
import { Popup, Cell, Dialog,Loading,Toast,Tabbar, TabbarItem,Tab, Tabs,Circle,Sticky, Swipe, SwipeItem ,NoticeBar} from 'vant';

const app = createApp(App);
app.use(router);
app.use(Popup);
app.use(Cell);
app.use(i18n);
app.use(Dialog);
app.use(Loading);
app.use(Toast);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Tab);
app.use(Tabs);
app.use(Circle);
app.use(Sticky);
app.use(SwipeItem);
app.use(Swipe);
app.use(NoticeBar);
app.mount('#app');
app.config.globalProperties.$axios = axios;

