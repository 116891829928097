const messages = {
  newtext: {
    senta: "返回",
    sentb: "每日動態收益",
    sentc: "權重平級收益",
    sentd: "請確認投資",
    sentde: "全球",
    sentf: "節點招募",
    sentg: "參與",
    senth: "資金配比",
    senti: "可投資金額",
    sentj: "收益",
    sentk: "池",
    sentaa: "總收益",
    sentq: "剩餘收益",
    sentaw: "閃",
    sentcs: "兌",
    sentxx: "國際行情排名",
    sentwf: "區塊鏈",
    sentcx: "人工智能",
    sentes: "我的",
    sentwz: "首頁",
    sssssss: "公告",
    sssssssaa: "售罄",
    ssszzzzzzzzz: "在售",
    likkkkk: "充值中,請勿離開",
    likkkkk2: "兌換中,請勿離開",
    likkkkk3: "提現中,請勿離開",
    likkkkk4: "正在注冊賬號",
    likkkkk5: "正在登錄賬號",
    likkkkk6: "交易生態",
  },
  ate: {
    placeholder: "請輸入需要質押的金額",
    title: "質押",
    title2: "發行總量",
    title3: "萬枚",
    title4: "鏈上質押算力挖磺",
    confirmPurchase: "請確認購買",
    purchaseComputingPower: "購買算力",
    cancel: "取消",
    confirm: "確認",
    totalNetworkComputingPower: "全網總算力",
    personalTotalComputingPower: "個人總算力",
    personalComputingPower: "個人算力",
    cumulativeComputingPower: "堆疊算力",
    personalComputingPowerRecord: "個人算力記錄",
    cumulativeComputingPowerRecord: "堆疊算力記錄",
    my: "我的",
    balance: "餘額",
    purchaseRecords: "購買記錄",
    buyNow: "立即購買",
    outputRecord: "產出記錄",
    enterAmountToExchange: "輸入需要兌換的金額",
    exchange: "兌換",
    exchangeRecords: "兌換記錄",
    kLineChart: "K線圖",
    minute: "分",
    hour: "時",
    day: "日",
    week: "周",
    purchaseSuccessful: "購買成功",
    purchaseFailed: "購買失敗",
    outputQuantity: "數量",
    computingPower: "算力值",
    downline: "下級",
    downlineAddress: "下級地址",
    generation: "代",
    cumulativeRewardRecord: "累積獎勵記錄",
    dasdad: "叠加獎勵記錄",
    dasdaddasdad: "購買算力",
    subRewardRecord: "下級獎勵記錄",
    month: "月",
    pleaseEnter: "請輸入",
    insufficientBalance: "餘額不足",
    exchangeSuccess: "兌換成功",
    exchangeFail: "兌換失敗",
    exchangeRewardPower: "兌換獎勵算力",
    subAddress: "下級地址",
    adddasxx: "請確認兌換",
  },
  global: {
    fillingtext: "此功能暫未開放",
  },
  axios: {
    power: "用戶未授權",
  },
  home: {
    introduce: "簡介",
    node: "節點",
    swap: "SWAP",
    Manage: "理財",
    Mining: "算力",
    earnings: "資產",
    team: "團隊",
    Share: "分享邀請碼",
    Records: "記錄",
    inviteTitle: "填寫邀請碼",
    inviteButton: "確定",
    invitePh: "請輸入推薦碼",
    dia1ogTitle: "使用者未授權（請刷新）",
    prompt: "請輸入有效的推薦碼！",
    message: "已復製",
    vite: "邀請碼",
  },
  team: {
    invest: "投資金額",
    investssss: "團隊人數",
    performance: "業績",
  },
  earnings: {
    quannnnn: "權重",
    ProfitPool: "收益池",
    sssss: "請輸入需要兌換的金額",
    flash: "閃兌",
    earningsxx: "網絡異常",
    balance: "我的余額",
    withdraw: "提現",
    filling: "充值",
    invest: "投資收益",
    team: "權重收益",
    Share: "分享收益",
    earnings: "收益總額",
    day: "每日釋放",
    released: "已釋放",
    dia1og1Title: "充值",
    dia1og1Ph: "輸入充值金額",
    dia1og1Banlance: "錢包余額",
    dia1og1B1: "取消",
    dia1og1B2: "確認",
    dia1og2Title1: "提現",
    dia1og2Title2: "提現金額",
    dia1og2Title3: "提現錢包地址",
    dia1og2Ph1: "輸入提現金額",
    dia1og2Ph2: "輸入提現錢包地址",
    tokenMsg: "token失效請重繪",
    WithdrawalMsgOK: "提現已提交",

    outalMsgOK: "轉賬已提交",
    outalMsgORR: "轉賬失敗",

    InsufficientResidualIncomePool: "剩餘收益池不足",
    TransferSameDayWithdrawal: "轉賬當日已轉賬或當日收到,不能撤回",
    AccountNotReceivedWithdrawal: "賬戶還沒有收到,所以還不能撤回",
    WithdrawalAmountGreaterThan100: "提現金額不能超過100",
    WithdrawalAmountGreaterThan300: "提現金額不能超過300",
    WithdrawalAmountGreaterThan500: "提現金額不能超過500",

    RechargeMsgOK: "充值已提交",
    WithdrawalMsgEORR: "提現失敗",
    RechargeMsgEORR: "充值失敗",
    network: "網絡差",
    Directthrust: "預計直推收益",
    desssL: "直推收益",
    manage: "管理收益",
    enter: "請輸入",
  },
  Mining: {
    Buyin: "合成方式：",
    onchain: "鏈上",
    System: "算力系統",
    truth: "真實鏈上數據，可鏈上取回",
    permanent: "長久",
    stabilize: "穩定",
    equity: "公平",
    just: "公正",

    machine: "算力",
    minitype1: "微型算力",
    minitype2: "小型算力",
    minitype3: "中型算力",
    minitype4: "大型算力",
    Buy: "合成",
  },
  node: {
    ktou: "可投資金額",
    tou: "投資",
    ytou: "預計投資收益",
    ltou: "立即投資",
    qtou: "請輸入需要投資的金額",
    by: "由",
    laboratory: "實驗室",
    forged: "打造的",
    ecology: "一個智能交易生態",
    Recruitment: "全球節點招募",
    Dynamic: "動態獎勵",
    may: "可以",
    fold: "折",
    obtained: "獲得",

    titleBig: "節點",
    title1: "swap初始節點",
    title2: "swap超級節點",
    title3: "swap創世節點",
    tip1: "优惠USDE",
    tip2: "名）",
    button: "立即購買",
  },
  swap: {
    text: "兌換成功",
    title: "SWAP",
    button0: "暂无節點",
    button1: "初始節點",
    button2: "超级節點",
    button3: "创世節點",
    balance: "余额",
    button: "確定",
  },
  Manage: {
    Participation: "參與方式",
    deposit: "入金方式",
    withdraw: "出金方式",
    invest: "投資金額及收益：",
    times: "倍",
    Daily: "日收益",
    title: "資金管理",
    input_num: "輸入數量",
    nswb: "錢包餘額",
    amount: "入金",
    ecumIncome: "預計累計收益",
    classInterest: "等級權益",
    unit: "倍",
    inamount: "投資",
    next: "下一步",
    minimum: "最少",
    balance: "余額不足",
    mang: "入金成功",
  },
  Records: {
    inmoy: "轉入",
    outmoy: "轉出",
    zzzddd: "收款",
    uaddd: "轉賬記錄",
    userAddress: "轉賬金額",
    listsss: "記錄",
    listss: "兌換",
    type: "類型",
    earnings: "下線節點兌換收益",
    deposit: "用戶入金",
    Buy: "購買節點",
    found: "創建時間",
    Lv: "節點等级",
    daymo: "每日收益",
    addmo: "預計增加收益池",
    addmot: "預計權重收益",
    amount: "入金金額",
    downline: "下線地址",
    my: "我的地址",
    Transfer: "轉賬",
    investment: "投資",
    depsmorng: "入金記錄",
    Recharge: "充值記錄",
    Withdrawal: "提現記錄",
    qpang: "其他",
    topup: "充值",
    address: "地址",
    withdraw: "提现",
    classInterest: "等級權益",
    complete: "已完成",
    underway: "充值中",
    unwithdraw: "提现中",
    Lose: "充值失敗",
    unwithLose: "提现失敗",
    ate1: "入金獎勵ATE算力",
    ate2: "複利獎勵ATE算力",
  },
  introduce: {
    t1: "人工",
    t2: "智慧交易",
    content: "致力於打造未來內容創作經濟新生態",
    title: [
      "AI智能交易",
      "的巨大機會",
      "瘋狂增長的",
      "去中心化",
      "衍生品交易積模",
      "區塊鏈",
      "人工智能",
      "技術團隊",
      "介紹",
      "Reuben Flack",
      "經濟",
      "模型",
    ],
    value: [
      "2022年初至今，加密貨幣市值由2.2萬億美元滑落至8500億美元，漫長的熊市極大打擊了投資者的信心和投資需求，DeFi協議總鎖倉價值 （TVL)也從年初的1670億美元下降到了440 億美元。然而，熊市中的長時間的震蕩及低波動率給交易帶來極大難度和挑戰。",
      "量化交易一直是金融領域中利潤最高的細分市場，近年來，在加密交易領域中各類事件頻發發，類似中心化交易所的黑箱操縱、FTX暴雷等事件，導致用戶對交易所的不信任與日俱增多重因素疊加，正在為量化交易賽道帶來機會更多的用戶正在選擇",
      "Ai Trading Ecology由美國投資機構NEO Global cap、BlombergBeta和羅馬尼亞投資機構BlockFront、Capital, 聯合發起並投資Brain Lab實驗室",
      "Bloomberg平台，Brain Lab實驗室專註於區塊鏈AI智能量化交易系統，自動做市商，聯合套利投資理財系統，未來系統將打造defi衍生品去中心化交易平合及公鏈與生態。",
      "Brain Lab實驗室是一家專註於AI智能與區塊鏈加密資產交易研究的實驗室。實驗室成立於2020年，致力於探索AI人工智能的算法機製和功能，並且致力於開發新的AI智能自動交易和技術，從而實現AI人工智能自動化做市商，為廣大交易者提供穩定、快捷方便、透明的交易體驗。",
    ],
    users: ["Reuben Flack", "Sanjay Vallabh", "Renuka Kuech", "Alex Baboolal"],
    modelData: [
      "鑄造",
      "AI托管訂單",
      "根據進入 金獲得",
      "2-3倍收益即出局",
      "產出",
      "算力值",
      "算力礦機",
      "ATE",
      "Power value",
    ],
    userMsg: [
      "用戶通過USDT+USDE鑄造AI訂單，獲得2.3—3倍等值算力產出收益",
      "用戶通過1:1USDT+USDE合成鑄造礦機產出ATE",
    ],
    foots: [
      "ATE",
      "機製",
      "ATE发行总量",
      "万枚",
      "极致通缩至",
      " 1万枚",
      "每日挖出剩余總量的萬分之5（遞減式產出）",
      "0私募、0預挖、0認購",
      "賣出ATE100%銷毀",
      "TRADING ECOLOGY",
      "未來展望",
      "Ai Trading Ecology生態主要憑證，在3.0生態衍生品中，ATE 是 Ai Trading Ecology生態鏈的主流代幣，",
      "區塊鏈遊戲，元宇宙，去中心化",
      "借貸等領域流通，也將通過跨鏈技術用於質押以實現挖礦主流代幣，未來升值的空間巨大！",
    ],
  },
};

export default messages;
